.spec-card.offersPage a:hover {
  color: rgb(1, 1, 207);
}

.spec-card.offersPage .img-cont {
  overflow: hidden;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.spec-card.offersPage a {
  color: #a99156;
  text-decoration: none;
}

.offer-slider .slick-dots li button::before {
  content: "";
  height: 12px;
  width: 18px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #a99156;
  border-radius: 40%;
}

.offer-slider .slick-dots {
  bottom: -33px;
}

.offer-slider h1 span {
  color: #fff;
}

.offer-slider .img-cont img {
  width: 100%;
}

.offer-slider a:hover {
  color: #a99156;
}

.offer-slider .slick-slide img {
  display: inline;
}

.offer-slider .slick-dots li button:before {
  font-size: 18px;
  color: #a99156;
}

.offer-slider .slick-dots li.slick-active button:before {
  color: #a99156;
}
.offerRow {
  transition: 0.3s;
}
.offerRow:hover {
  background-color: lightgrey;
}
@media (min-width: 270px) {
  .spec-card.offersPage {
    border-radius: 8px;
    margin-bottom: 24px;
  }
  .offer-big-slider {
    background-color: #fff;
    border: none;
    border-radius: 500px;
    color: #a99156;
    cursor: default;
    font-size: 0.7rem;
    font-weight: 700;
    padding: 0.2rem 0.8rem;
  }

  .spec-card.offersPage .img-cont .top-img {
    margin: auto;
    display: block;
    object-fit: cover;
    height: 230px;
    background-color: #f1eee6;
  }

  .row .offersPadding {
    padding: 0 12px;
  }

  .offer-slider .offer-cont {
    background-position: top right;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }

  .offer-slider h1 {
    font-size: 1rem;
    font-weight: bold;
    width: 60%;
    margin-bottom: 1rem;
  }

  .offer-slider .offer-cont .offer-slide-col {
    padding: 1rem;
  }

  .offer-slider a {
    color: #a99156;
    background-color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    font-size: 0.7rem;
    text-decoration: none;
  }

  .offer-slider a img {
    width: 15px;
  }

  .offers-cards-box {
    margin-top: 50px;
  }

  .offer-slider {
    border-radius: 12px;
    position: relative;
    background-position: right;
    background: linear-gradient(to right, #dfc483, #e9d39e);
  }
}
@media (max-width: 412px) {
  .offer-slider {
    height: 10rem;
    min-height: 10rem;
  }
}
@media (min-width: 412px) {
  .offer-slider {
    height: 10rem;
    min-height: 10rem;
  }

  .offer-slider .offers-white {
    color: white;
  }

  .offer-slider .offer-cont {
    height: 10rem;
  }

  .offer-slider h1 {
    font-size: 1.3rem;
    font-weight: bold;
    width: 50%;
    margin-bottom: 1rem;
  }

  .offer-slider a {
    font-size: 0.9rem;
  }
}

@media (min-width: 540px) {
  .offer-slider {
    height: 14rem;
  }

  .offer-slider .offer-cont {
    height: 14rem;
  }

  .offer-slider h1 {
    font-size: 2rem;
    font-weight: bold;
    width: 50%;
    margin-bottom: 2rem;
  }

  .spec-card.offersPage .img-cont .top-img.offer {
    object-fit: contain;
  }

  .offer-slider .offer-cont .offer-slide-col {
    padding: 1.2rem;
  }

  .offer-slider a {
    font-size: 1rem;
  }

  .offersPadding .card-body {
    font-size: 1rem;
  }

  .offersPadding .card-title {
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) {
  .offer-slider {
    height: 16rem;
  }
  .offer-image {
    height: 16rem;
  }

  .offer-slider .offer-cont {
    height: 16rem;
  }
  .offer-big-slider {
    font-size: 0.9rem;
    padding: 0.4rem 2rem;
  }
  .offer-slider h1 {
    font-size: 2.5rem;
    width: 90%;
  }

  .spec-card.offersPage .img-cont .top-img.offer {
    object-fit: cover;
  }
}

@media (min-width: 1024px) {
  .offer-slider {
    height: 18rem;
  }
  .offer-image {
    height: 18rem;
  }

  .offer-slider .offer-cont {
    height: 18rem;
  }

  .offer-slider .offer-cont .offer-slide-col {
    padding: 1.4rem;
  }

  .offer-slider h1 {
    font-size: 3.2rem;
    width: 100%;
  }
}

@media (min-width: 1280px) {
  .offer-slider {
    height: 22rem;
  }
  .offer-image {
    height: 22rem;
  }

  .offer-slider .offer-cont {
    height: 22rem;
  }

  .offer-slider .offer-cont .offer-slide-col {
    padding: 2rem;
  }

  .offer-slider h1 {
    font-size: 3.6rem;
    width: 95%;
  }

  .offer-slider a {
    padding: 0.7rem 1.5rem;
    font-size: 1.2rem;
  }
}

@media (min-width: 1440px) {
  .offer-slider {
    height: 22rem;
  }
  .offer-iamge {
    height: 28rem;
  }

  .offer-slider .offer-cont {
    height: 28rem;
  }

  .offer-slider .offer-cont .offer-slide-col {
    padding: 2.5rem;
  }

  .offer-slider h1 {
    font-size: 4.5rem;
    width: 100%;
  }

  .offer-slider a {
    padding: 0.8rem 1.8rem;
    font-size: 1.3rem;
  }
}
