.patientNavGroup {
  display: flex;
  flex-direction: column;
}
.appointment-doctor {
  width: 6rem;
  height: 6rem;
  object-fit: contain;
  border-radius: 0.8rem;
}
.patientNavBtn {
  flex: 1;
  text-align: start;
  background-color: transparent;
  border: none;
  border-bottom: 0.1rem solid #a99156;
  padding: 0.8rem 3rem;
  text-decoration: none;
  color: black !important;
}
.appointment-title {
  font-size: 1rem;
  align-self: center;
}
@media (min-width: 1024px) {
  .appointment-title {
    font-size: 1.5em;
  }
}
.patientNav {
  display: flex;
  align-items: center;
}

.selected {
  color: #a99156 !important;
}

.logout {
  color: red !important;
}

.save {
  background-color: #a99156;
  color: white;
}

.save:hover {
  background-color: #a99156;
}

.patientForm .form-control {
  background-color: #fcfaf3;
  border: 0.1rem solid #ebe1c9;
  width: 60%;
}

.patientForm .form-control:focus {
  background-color: #fcfaf3;
  border: 0.1rem solid #ebe1c9;
  box-shadow: none;
}

.input-group-text {
  background-color: #fcfaf3 !important;
  border: 0.1rem solid #ebe1c9 !important;
  border-left: none !important;
}

.custom-card {
  width: 80%;
  margin-bottom: 1rem;
  border-radius: 0.4rem !important;
  animation: fadeIn 0.6s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(80px);
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.card-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 11rem;
  padding: 0.5rem;
}

.card-div {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0;
}
.card-div div:nth-child(2) {
  flex-grow: 1;
}
@media (min-width: 540px) {
  .card-div {
    font-size: 1rem;
  }
}
.card-col-2-div {
  display: flex;
  align-items: center;
  margin: 0.3rem 0;
}

.custom-divider {
  background-color: #e6d5ad;
  height: 0.1rem;
  position: relative;
  top: 1rem;
  margin: 0.5rem 0;
}
.custom-card-footer-container {
  display: flex;
  justify-content: space-between;
}
.custom-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .custom-card-footer {
    flex-direction: row;
  }
}
.custom-card-button {
  text-transform: none;
  padding: 0;
  border-bottom: 0.2rem solid #a99156;
  border-radius: 0;
  margin-right: 0.3rem;
}

.patient-gender {
  display: "flex";
}

.patient-password {
  width: 60% !important;
}

.patientNav-img {
  margin-right: -2rem;
}

@media screen and (max-width: 992px) {
  .custom-card {
    width: 100%;
    font-size: 80%;
  }
}

@media screen and (max-width: 769px) {
  .patientNavGroup {
    min-height: 0 !important;
    justify-content: center;
    align-items: center;
  }

  .patientNavBtn {
    border: none;
  }

  .patientForm .form-control {
    width: 100%;
  }

  .patient-gender {
    width: 100%;
  }

  .patient-password {
    width: 100% !important;
  }

  .custom-card {
    width: 100%;
    font-size: 80%;
  }
}

@media screen and (max-width: 476px) {
  .custom-card {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 410px) {
  .custom-card {
    font-size: 0.8rem;
    margin: 0.5rem 0;
  }
}
.custom-card-footer {
  padding-right: 0;
  padding-left: 0;
}

.customBtn {
  padding: 0.5rem 3rem;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  border-radius: 0.5rem;
}
.AppointcustomBtn {
  padding: 0.1rem 0.5rem;
  text-transform: uppercase;
  border: none;
  font-weight: bold;

  border-radius: 0.5rem;
}
