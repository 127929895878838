@media (min-width: 360px) {
  .hero-wrapper {
    position: relative;
    max-height: 650px;
  }
  .hero-container .hero-backGround {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 650px;
  }

  .hero-wrapper .searchbar-box {
    position: relative;
    top: -25rem;
    z-index: 2;
  }
}

@media (min-width: 768px) {
  .hero-container .hero-text {
    display: block;
    font-size: 1.3rem;
  }
  .hero-text {
    width: 90%;
  }
}

@media (min-width: 1024px) {
  .hero-wrapper .searchbar-box {
    top: -15rem;
  }

  .hero-text {
    width: 65%;
  }
}

@media (min-width: 1440px) {
  @media (min-width: 1024px) {
    .hero-wrapper .searchbar-box {
      top: -13rem;
    }
  }
}
