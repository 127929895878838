.dashboard-container {
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  padding-bottom: 2rem;
  @media (max-width: 768px) {
    margin-left: 0;
  }

  .dashboard-wrapper {
    padding: 1rem;
    .profile-images {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      .images-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        .image-with-remove-icon {
          border: 1px solid #000;
          margin: 5px;
          position: relative;
          background-color: #fff;
          &::after {
            display: none;
            content: "x";
            position: absolute;
            height: 15px;
            width: 15px;
            line-height: 10px;
            font-size: 12px;
            color: #fff;
            background-color: red;
            top: -2px;
            right: -2px;
            border-radius: 5px;
          }
          &:hover {
            &::after {
              display: block;
            }
          }
        }
      }
    }
    .dashboard-locations {
      .reversed-switch-row {
        @media screen and (max-width: 991.5px) {
          flex-direction: column-reverse;
        }

        .closed-switch {
          display: grid;
          place-items: center;
          height: 100%;
          &-box {
            display: flex;
            justify-content: center;
            width: 100%;
            span {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
    .booking-table {
      & > div {
        overflow-x: scroll;
      }
      .spicy-datatable {
        width: 100%;
        overflow: scroll;
        padding: 0;
        margin: 0 auto;
        background: #fff;
        font-size: 0.875rem;
        text-align: left;
        border-spacing: 0;
      }

      .spicy-datatable > tbody > tr > td,
      .spicy-datatable > tfoot > tr > td,
      .spicy-datatable > thead > tr > th {
        box-sizing: border-box;
        height: 50px;
        min-width: 150px;
        padding: 0 20px;
        border: none;
        border-top: 1px solid #ddd;
        word-break: break-all;
        vertical-align: middle;
      }

      .spicy-datatable > tbody > tr {
        transition: 0.25s all;
      }

      .spicy-datatable > tbody > tr:hover {
        background: whitesmoke;
      }

      .spicy-datatable > thead > tr > th {
        border-top: 0;
      }

      /*
        === ENTRY COUNTER AT BOTTOM ===
      */

      .spicy-datatable-counter {
        text-align: left;
        font-size: 12px;
        padding: 0 20px;
      }

      /*
        === PAGINATION SETTINGS ===
      */

      .spicy-datatable-pagination-root {
        width: 100%;
        position: relative;
        margin: 1rem auto;
        overflow: auto;
      }

      .spicy-datatable-pagination-list {
        list-style: none;
        display: inline-block;
        position: relative;
        background: #fff;
        margin: 0;
        padding: 0;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .spicy-datatable-pagination-list .spicy-datatable-pagination-button {
        font-size: 12px;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        color: #ccc;
        height: 2.5rem;
        width: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
        border-radius: 100%;
        transition: 0.25s all;
      }

      .spicy-datatable-pagination-list
        .spicy-datatable-pagination-button-elipsis:hover {
        background: #fff !important;
      }

      .spicy-datatable-pagination-list
        .spicy-datatable-pagination-button.active {
        background: #a9a9a9;
        color: #fff;
      }

      .spicy-datatable-pagination-list
        .spicy-datatable-pagination-button:hover {
        background: #a9a9a9;
        color: #fff;
      }

      .spicy-datatable-pagination-list
        .spicy-datatable-pagination-button-elipsis:hover {
        background: #fff !important;
        color: #ccc;
        cursor: default;
      }

      /*
        === TOP OPTIONS ===
      */

      .spicy-datatableoptions-search {
        text-align: right;
        float: right;
      }

      .spicy-datatableoptions-sizepicker {
        float: left;
        text-align: left;
        cursor: pointer;
      }

      .spicy-datatableoptions-sizepicker--selectfield {
        margin: 0 5px;
      }

      .spicy-datatableoptions-search--input {
        margin-left: 10px;
      }

      .spicy-datatableoptions-export--button-wrapper {
        float: left;
        clear: both;
      }
    }
  }
}
