.topServices h1 {
  font-weight: 400;
}

.topServices h1 span {
  color: #a99156;
  font-weight: bold;
}

.service .cardText {
  padding: 15px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.service .service-img {
  width: 100px;
  position: relative;
  display: flex;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 15px;
  justify-content: center;
  align-items: center;
}

.service .circle-cont {
  width: 77px;
  position: absolute;
  left: -72px;
  overflow: hidden;
  background-color: transparent;
  height: 170px;
}

.service .circle-cont .circle {
  width: 198px;
  left: 39px;
  top: -27px;
  height: 216px;
  position: absolute;
  border-radius: 50%;
}

.service .service-img .img-cont {
  width: 120px;
  right: 0;
  position: absolute;
}

.service .service-img .img-cont img {
  width: 100%;
}

.topServices a {
  color: #a99156;
}

@media (min-width: 360px) {
  .topServices {
    padding: 30px 0;
  }

  .topServices p {
    width: 90%;
  }

  .topServices .service {
    min-height: 170px;
    display: flex;
    margin-bottom: 24px;
    border-radius: 8px;
    justify-content: space-between;
    box-shadow: -1px 0 16px #00000025;
    background-color: white;
  }

  .more-service {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    background-color: #26486d;
    height: fit-content;
  }

  .more-service .more-link {
    font-size: 22px;
    text-decoration: none;
    color: #dacaa2;
    font-weight: bold;
  }
}

@media (min-width: 768px) {
  .more-service {
    min-height: 170px;
    border: 2px solid #a99156;
    background-color: #a99156;
  }

  .more-service .more-link {
    text-decoration: underline;
    color: white;
  }
}
