body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Century Gothic", sans-serif;
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom, #fef8e961 2%, white 98%);
}

#root {
  overflow-x: hidden;
}

.slick-next,
.slick-prev {
  top: 18px;
  z-index: 5;
}

.active-day {
  position: relative;
}

.active-day::after {
  display: block;
  content: "";
  width: 80px;
  height: 3px;
  background-color: #a99156;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%);
}

.bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.p-top-100 {
  padding-top: 100px;
}

.m-top-30 {
  margin-top: 30px;
}

.p-top-50 {
  padding-top: 50px;
}

p {
  word-break: break-all;
}

.mainColor {
  color: #a99156;
}

.btn-primary {
  --cui-btn-shadow: transparent !important;
  --cui-btn-bg: #a99156 !important;
  --cui-btn-border-color: '#0000' !important;
  --cui-btn-active-border-color: 'red' !important;
  --cui-btn-active-bg: transparent !important;
  --cui-btn-active-border-color: transparent !important;
  --cui-btn-hover-bg: transparent !important;
  --cui-btn-hover-border-color: transparent !important;
  --cui-btn-hover-color: white !important;
  --cui-btn-disabled-bg: transparent !important;
  /* --cui-btn-disabled-border-color: transparent !important; */
  /* --cui-btn-shadow: transparent !important; */
}

.btn-primary:hover {

  /* --cui-primary:red !important; */
}

.dropdown-item {
  background-color: red;
}

.bg-gold {
  background-color: #a99156 !important;
}

.pop-up {
  z-index: 1000;
  background-color: #a99156 !important;
  color: white !important;
  border: none !important;
  transition: all 0.1s ease-in-out !important;
}

.accordion-button:focus {
  box-shadow: unset !important;
}

.hoverGold:hover {
  background-color: #a99156 !important;
  color: white !important;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.pop-up:hover {
  box-shadow: 2px 6px 10px rgba(26, 26, 26, 0.158) !important;
  transform: translateY(-2px) !important;
}

.pop-up2 {
  border: none;
  color: #a99156;
  text-align: start;
  background-color: transparent;
  transition: all 0.1s ease-in-out;
  border-radius: 0.4rem;
  padding: 0.7rem 0.5rem;
}

.pop-up2:hover {
  background-color: #a99156;
  color: white;
  text-decoration: none;
  box-shadow: 2px 6px 10px rgba(26, 26, 26, 0.158);
  transform: translateY(-2px);
}

.card-shadow {
  box-shadow: -1px 0 10px #00000025;
}

.patient-profile-col {
  min-height: 20rem;
  margin: 5rem 0;
}

.patient-nav-col {
  border-right: 0.1rem solid #a99156;
  padding-right: 0 !important;
}

.form-check-input:checked[type="radio"] {
  background-image: none !important;
  box-shadow: none;
}

.form-check-input:checked {
  background-color: #a99156 !important;
  border-color: white !important;
  border-width: 1.5px !important;
  outline: solid 1.5px #a99156 !important;
}

.cancel {
  color: #a99156;
  background-color: transparent;
}

.cancel:hover {
  color: #a99156;
  background-color: transparent;
  box-shadow: none;
}

.card-shadow {
  box-shadow: -1px 0 10px #00000025;
}

@media screen and (max-width: 768px) {
  .patient-nav-col {
    border: none;
    margin: 0 auto;
  }

  .patient-profile-col {
    min-height: 5rem;
  }

  .pop-up {
    outline: none;
    border: none;
    padding: 10px;
    border-radius: 10px;
  }
}

.close-button:hover {
  background-color: red !important;
}

.close-button {
  margin:2px;
  padding: 0;
  display: flex;
  /* right:0px; */
  position: absolute;
  justify-content: center;
  align-items: center;
  /* width: 20px; */
  background-color: gray !important;
  /* height: 20px; */
}

.image-with-remove-icon::after {
  /* display: none; */
  content: "x";
  position: absolute;
  /* height: 10px; */
  /* width: 10px; */
  /* line-height: 10px; */
  font-size: 10px;
  color: #fff;
  background-color: red;
  top: -2px;
  right: -2px;
  border-radius: 5px;
}

.image-with-remove-icon:hover::after {

  display: block;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-with-remove-icon {
  /* position: absolute; */
  top: 10px;
  /* right: 10px; */
  border: 1px solid #000;
  /* margin: 5px; */
  /* position: relative; */
  /* background-color: blue !important; */
}

.icon-img-size {
  width: 18px;
}

::-webkit-scrollbar-thumb {
  background-color: #c5a85f;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a99156;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}