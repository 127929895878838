.or-box {
  position: relative;
  margin-bottom: 2rem;
}
.bookingFormWidth {
  width: 20rem !important;
}
.or-box hr {
  height: 0.1rem;
  background-color: black;
  opacity: 1;
}

.auth-cont .form-check-input {
  border: 1px solid #e2d6b6;
}
.auth-cont.forgot {
  display: flex;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}
.auth-cont.forgot form {
  margin: 4rem 0;
}
.or-box span {
  position: absolute;
  background-color: #fffbf3;
  top: 0;
  padding: 5px 12px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.auth-cont button.signup {
  background-color: #a99156;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  border: 1px solid #a99156;
  padding: 10px 50px;
  font-size: 15px;
  border-radius: 10px;
}

.terms a {
  color: #a99156;
  text-decoration: underline;
}

button.my-google-button img {
  width: 20px;
  margin-right: 5px;
}

.my-facebook-button .fa-facebook {
  margin-right: 5px;
}

.auth-cont button.login {
  background-color: #a99156;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  border: 1px solid #a99156;
  padding: 10px 100px;
  font-size: 15px;
  border-radius: 10px;
}
.back-auth:lang(ar) {
  background-position: top left;
}
@media (min-width: 360px) {
  .back-auth {
    background: none;
    min-height: 50rem;
  }

  button.my-google-button {
    background-color: #fff;
    border: 1px solid #6a8097;
    color: rgb(0, 0, 0);
    font-weight: bold;
    padding: 0.5rem 3.6rem;
    border-radius: 0.4rem;
    margin: 0.5rem auto;
    width: max-content;
  }

  .my-facebook-button {
    background-color: #3a559f;
    border: none;
    color: white;
    width: max-content;
    padding: 0.5rem 3.6rem;
    border-radius: 0.4rem;
    margin: 0.5rem auto;
  }
  .my-facebook-button:lang(ar) {
    direction: ltr;
  }
  .auth-cont .form-control {
    background-color: #fcfaf3;
    border: 0.05rem solid #e2d6b6;
    border-radius: 0.4rem;
    width: 13rem;
  }

  .auth-cont .form-label {
    color: #a99156;
    font-size: 15px;
  }

  .auth-cont .checks {
    width: 13rem;
  }

  .auth-cont .terms {
    font-size: 0.9rem;
  }

  .auth-cont .terms hr {
    height: 0.1rem;
    background-color: black;
    opacity: 1;
  }
  .err-box {
    display: flex;
    line-height: 1.1rem;
    font-size: 0.9rem;
    justify-content: space-between;
  }
  .err-box .empty {
    width: 37%;
  }
  .err-box .err {
    width: 63%;
  }
}

@media (min-width: 540px) {
  .auth-cont .checks {
    width: 21rem;
  }

  .auth-cont .form-control {
    width: 21rem;
  }

  button.my-google-button {
    padding: 0.5rem 8rem;
  }
  button.my-google-button:lang(ar) {
    direction: ltr;
  }
  .my-facebook-button {
    padding: 0.5rem 8rem;
  }
  .err-box .empty {
    width: 35%;
  }
  .err-box .err {
    width: 65%;
  }
}

@media (min-width: 768px) {
  .auth-cont .checks {
    width: 20rem;
  }

  .auth-cont .form-control {
    width: 20rem;
  }

  .auth-cont {
    width: 70%;
    margin: auto;
  }

  button.my-google-button {
    padding: 0.5rem 8rem;
  }

  .my-facebook-button {
    padding: 0.5rem 8rem;
  }
}

@media (min-width: 912px) {
  .back-auth {
    min-height: 54rem;
  }
}

@media (min-width: 1024px) {
  .back-auth {
    background-color: transparent;
    background-image: url(/public/assets/login-signup.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 55% 100%;
  }

  .auth-cont .checks {
    width: 15rem;
  }

  .auth-cont {
    width: 40%;
    margin: 0;
  }

  .auth-cont.loginPage {
    margin: 25px 0;
  }

  .auth-cont .form-control {
    width: 15rem;
  }

  button.my-google-button {
    padding: 0.5rem 5rem;
  }

  .my-facebook-button {
    padding: 0.5rem 5rem;
  }
}

@media (min-width: 1280px) {
  .back-auth {
    background-size: 50% auto;
  }

  .auth-cont .checks {
    width: 18rem;
  }

  .auth-cont .form-control {
    width: 18rem;
  }

  .auth-cont.loginPage {
    margin: 80px 0;
  }

  button.my-google-button {
    padding: 0.5rem 7rem;
  }

  .my-facebook-button {
    padding: 0.5rem 7rem;
  }
}
@media (min-width: 1400px) {
  .err-box .empty {
    width: 45%;
  }
  .err-box .err {
    width: 55%;
  }
}
@media (min-width: 1440px) {
  .auth-cont {
    width: 40%;
    margin: 0;
  }

  .auth-cont.loginPage {
    margin: 100px 0;
  }

  .auth-cont .form-control {
    width: 20rem;
  }

  .auth-cont .checks {
    width: 20rem;
  }

  button.my-google-button {
    padding: 0.5rem 10rem;
  }

  .back-auth {
    min-height: 1000px;
  }

  .my-facebook-button {
    padding: 0.5rem 10rem;
  }
  .err-box .empty {
    width: 39%;
  }
  .err-box .err {
    width: 61%;
  }
}

@media (min-width: 1920px) {
  .auth-cont {
    margin-top: 5rem;
  }
}
