.success-booking-cont h3 {
  text-align: center;
  color: #a99156;
}

.success-booking-cont .muted {
  color: rgba(68, 68, 68, 0.493);
  text-decoration: line-through;
  font-size: 1.1rem;
}

.success-booking-cont hr {
  opacity: 1;
  background-color: #a99156;
}

.success-booking-cont hr:not([size]) {
  height: 1.8px;
}

.success-booking-cont hr:not([size]).hr-line {
  height: 1.2px;
}

.success-booking-cont button {
  background-color: #a99156;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  border: 1px solid #a99156;
  padding: 14px 35px;
  font-size: 15px;
  border-radius: 10px;
}

@media (min-width: 360px) {
  .back-booking {
    background: linear-gradient(to bottom, #fef8e9 4%, white 96%);
    background-repeat: no-repeat;
    min-height: 800px;
  }

  .success-booking-cont h5 {
    line-height: 1.5;
    font-size: 0.95rem;
  }
}

@media (min-width: 768px) {
  .success-booking-cont {
    width: 70%;
    margin: auto;
  }
}

@media (min-width: 912px) {
  @media (min-width: 912px) {
    .back-booking {
      min-height: 55rem;
    }
  }
}

@media (min-width: 1024px) {
  .success-booking-cont {
    width: 40%;
    margin: 0;
  }

  .back-booking {
    background-color: transparent;
    background-image: url(/public/assets/successfully-submited.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 55% 100%;
  }
}

@media (min-width: 1280px) {
  .back-booking {
    background-size: 50% auto;
  }
}

@media (min-width: 1440px) {
  .success-booking-cont {
    width: 40%;
    margin: 0;
  }

  .back-booking {
    min-height: 1000px;
  }
}

@media (min-width: 1920px) {
  .success-booking-cont h5 {
    line-height: 1.5;
    font-size: 1.25rem;
  }

  .success-booking-cont {
    margin-top: 5rem;
  }
}
