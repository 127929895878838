.doc-clinicPhotos {
  padding-bottom: 20px;
  border-bottom: #a99156 2px solid;
}

.doc-clinicPhotos .images-cont .lg-react-element {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  grid-gap: 15px; */
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 1rem;
}
.test div:nth-of-type(3n + 2) {
  display: none;
}
.gallery .lg-backdrop {
  background-color: rgba(0, 0, 0, 0.63);
}

.gallery .lg-components {
  bottom: 45px;
}

.gallery .lg-outer .lg-img-wrap {
  bottom: 45px;
  top: -25px;
}

.gallery .lg-outer .lg-thumb-item {
  opacity: 0.6;
  border-color: transparent;
}

.gallery .lg-toolbar .lg-download:after {
  content: "";
}

.gallery #lg-download-1 {
  display: none;
}

.gallery .lg-close.lg-icon::after {
  content: "";
}

.gallery .lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  border-radius: 8px;
}

.gallery .lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item img {
  border-radius: 8px;
}

.gallery .lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: transparent;
  opacity: 1;
}

.gallery .lg-next,
.gallery .lg-prev {
  background-color: transparent;
}

.gallery .lg-outer .lg-thumb-outer {
  background-color: transparent;
}

.doc-clinicPhotos .more-image-link {
  position: relative;
}

.doc-clinicPhotos .more-images {
  display: none;
}

.doc-clinicPhotos .lg-outer .lg-object.more-images {
  display: inline-block;
}

.doc-clinicPhotos .black-overlay {
  position: absolute;
  top: 0;
  z-index: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.637);
  width: 116px;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 360px) {
  .gallery .lg-outer .lg-object {
    border-radius: 10px;
    width: 80%;
    object-fit: contain;
  }

  .gallery .lg-next.lg-icon {
    background-image: url(/public/assets/right.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 50px;
    width: 50px;
    position: absolute;
    right: -1%;
    top: 41%;
  }

  .gallery .lg-next.lg-icon::before {
    content: "";
  }

  .gallery .lg-prev.lg-icon {
    background-image: url(/public/assets/left.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 50px;
    width: 50px;
    position: absolute;
    left: -1%;
    top: 41%;
  }

  .gallery .lg-close.lg-icon {
    background-image: url(/public/assets/close.svg);
    background-repeat: no-repeat;
    top: 28px;
    right: 2px;
    position: absolute;
  }

  .gallery .lg-prev.lg-icon::after {
    content: "";
  }

  .mobile-show {
    display: flex;
    width: 48%;
    height: 10rem;
    margin: 2px;
  }
  .more-image-link {
    width: 48%;
    margin: 2px;
  }
  .doc-clinicPhotos .black-overlay {
    width: 100%;
  }

  .doc-clinicPhotos {
    background-color: white;
    border-radius: 5px;
    box-shadow: -1px 0 10px #00000025;
    border-bottom: none;
    margin-bottom: 20px;
    padding: 15px;
  }

  .doc-clinicPhotos .images-cont .lg-react-element {
    display: flex;
    flex-wrap: wrap;
    /* grid-gap: 3px;
    grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr)); */
  }

  .doc-clinicPhotos .images-cont img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 0.6rem;
    border-radius: 10px;
  }
}

@media (min-width: 412px) {
  .gallery .lg-next.lg-icon {
    top: 45%;
  }

  .gallery .lg-prev.lg-icon {
    top: 45%;
  }
}

@media (min-width: 540px) {
  .gallery .lg-prev.lg-icon {
    left: 1%;
  }

  .gallery .lg-next.lg-icon {
    right: 1%;
  }
}

@media (min-width: 768px) {
  .mobile-show {
    width: 31%;
    height: 10rem;
    margin: 2px;
  }
  .more-image-link {
    width: 31%;
    margin: 2px;
  }

  .gallery .lg-outer .lg-object {
    height: 65%;
  }
}

@media (min-width: 1024px) {
  .gallery .lg-outer .lg-object {
    width: 65%;
    height: 80%;
  }

  .gallery .lg-prev.lg-icon {
    left: 10%;
  }

  .gallery .lg-next.lg-icon {
    right: 10%;
  }

  .doc-clinicPhotos {
    width: 52%;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 0.1rem solid #a99156;
    margin-bottom: 20px;
    padding: 0;
  }

  .doc-clinicPhotos .mobile-hide {
    display: flex;
    width: 31%;
    height: 10rem;
    margin: 2px;
  }
}

@media (min-width: 1280px) {
  .doc-clinicPhotos .mobile-hide {
    display: flex;
    width: 18%;
    height: 10rem;
    margin: 2px 4px;
  }
  .more-image-link {
    width: 18%;
    margin: 2px 4px;
  }
  .doc-clinicPhotos {
    width: 55%;
  }
}

@media (min-width: 1440px) {
  .doc-clinicPhotos {
    width: 60%;
  }
}
