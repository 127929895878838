.reverse:lang(ar) {
  flex-direction: row-reverse;
}
.modal-header:lang(ar) {
  flex-direction: row-reverse;
  justify-content: space-between;
}
.booking-item:lang(ar) {
  direction: rtl;
  display: flex;
}
.modal-body:lang(ar) {
  direction: rtl;
}
.space:lang(ar) {
  justify-content: space-between !important;
}
.slick-list {
  height: 100%;
}
.slick-track {
  height: 100%;
}
.slick-slide > div {
  height: 100%;
}
