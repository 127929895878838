.services h1 {
  font-weight: 400;
}

.services h1 span {
  color: #a99156;
  font-weight: bold;
}

.services .search {
  width: 300px;
  border: #e0d3b2 2px solid;
}

.services .service {
  min-height: 170px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: -1px 0 16px #00000025;
}

.services .service-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.services h1 {
  position: relative;
  top: -0.2rem;
}

.services #formBasicEmail {
  position: relative;
  top: 0.1rem;
}

.services .service-box .form-control {
  border-radius: 0.4rem;
}

.services a {
  color: #a99156;
}

.services-faq {
  padding-bottom: 60px;
}

.services-faq h1 {
  font-size: 50px;
}

.services-faq .accordion-button:not(.collapsed) {
  color: black;
  background-color: white;
  box-shadow: none;
}

.services-faq .accordion-button {
  font-weight: bold;
}

.services-faq .accordion-button:not(.collapsed)::after {
  background-image: url(/public/assets/minus.svg);
}

.services-faq .accordion-button::after {
  background-image: url(/public/assets/plus.svg);
}

.services-faq .accordion-box .accordion-item {
  border: none;
  border-radius: 10px;
}

.services-faq
  .accordion-box
  .accordion-item:last-of-type
  .accordion-button.collapsed,
.services-faq .accordion-box .accordion-item .accordion-button {
  border-radius: 10px;
}

.services-faq .accordion-box {
  margin: 15px 0;
  border-radius: 10px;
}

@media (min-width: 360px) {
  .services .service-box {
    flex-direction: column;
  }

  .services p {
    width: 90%;
    font-size: 0.9rem;
  }

  .services a,
  .services h6 {
    font-size: 0.8rem;
  }
}

@media (min-width: 540px) {
  .services a,
  .services h6 {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) {
  .services a,
  .services h6 {
    font-size: 1.1rem;
  }

  .services .service-box {
    flex-direction: row;
    padding: 2rem 0;
  }
}

@media (min-width: 1024px) {
  .services a,
  .services h6 {
    font-size: 0.9rem;
  }
  .services p {
    width: 100%;
  }
}

@media (min-width: 1280px) {
  .services a,
  .services h6 {
    font-size: 1rem;
  }
}

@media (min-width: 1440px) {
  .services p {
    font-size: 1.1rem;
  }
  .services p {
    width: 90%;
  }
}
