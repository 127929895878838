.doc-faq .accordion-button:not(.collapsed) {
  color: black;
  background-color: white;
  box-shadow: none;
}

.doc-faq .accordion-button:not(.collapsed)::after {
  background-image: url(/public/assets/minus.svg);
}

.doc-faq .accordion-button::after {
  background-image: url(/public/assets/plus.svg);
}

.doc-faq .accordion-button {
  font-weight: bold;
}

.doc-faq .accordion-box .accordion-item {
  border: none;
  border-radius: 10px;
}

.doc-faq
  .accordion-box
  .accordion-item:last-of-type
  .accordion-button.collapsed,
.doc-faq .accordion-box .accordion-item .accordion-button {
  border-radius: 10px;
}

@media (min-width: 360px) {
  .doc-faq .accordion-box {
    margin: 15px 0;
    border-bottom: 0.1rem solid #a99156;
  }
}

@media (min-width: 540px) {
  .doc-faq .accordion-box {
    border-bottom: none;
    border-radius: 5px;
    box-shadow: -1px 0 10px #00000025;
  }
}
