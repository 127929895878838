.doctors {
  flex: 4;
}

.doctor-card.card {
  border-radius: 14px;
  min-height: 235px;
}
.doctor-appoinments {
  max-height: 10rem;
  overflow-y: scroll;
}
.doctors a {
  color: #a99156;
  font-weight: bold;
}

/* .doctor-card .left {
  min-width: 470px;
} */

.doctor-card .right {
  max-width: 360px;
  margin: auto;
}

.sep-back {
  background-color: #c1c11b14;
}

.sep2-back {
  background-color: #f6f6eb33;
}

.doctors .doctor-card .slick-slide {
  text-align: center;
}

.fa-chevron-right {
  color: #a99156;
}

.doctor-card .right .active-day {
  position: relative;
}

.doctor-card .slick-prev {
  left: -13px;
}

.doctor-card .slick-next {
  right: -13px;
}

.doctor-card .right .swiper-slide {
  cursor: pointer;
}

.doctor-card .slick-list {
  overflow: visible;
}

.doctor-card .row {
  overflow: hidden;
}

.doctor-card .right .active-day::after {
  display: block;
  content: "";
  width: 80px;
  height: 3px;
  background-color: #a99156;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%);
}

.pagination .page-link {
  border: none;
  color: black;
}

.pagination .page-item.active .page-link {
  color: white;
  background-color: black;
}

@media (min-width: 360px) {
  .doctor-card .alte-right button:nth-of-type(1) {
    background-color: #dacaa2;
    cursor: default;
    border: none;
    padding: 0.6rem 0.7rem;
    font-size: 0.78rem;
  }

  .doctor-card button {
    background-color: #fef8e8;
    border: 1px solid #a99156;
    padding: 5px 15px;
    font-size: 15px;
    border-radius: 10px;
  }
  .doctor-card button.pop-up2 {
    background-color: transparent;
    text-decoration: underline;
    border: none;
  }
  .doctor-card button.pop-up2:hover {
    color: white;
    background-color: #a99156;
  }
  .doctor-card .alte-right button.book {
    background-color: #26486d;
    border: none;
    padding: 0.6rem 0.7rem;
    font-size: 0.78rem;
    font-weight: bold;
    color: #dacaa2;
  }

  .text-muted-dashed {
    color: #6c757d;
    text-decoration: line-through;
    font-size: 0.8rem;
  }

  .doctor-card .left .img-cont {
    width: 5rem;
    overflow: hidden;
    height: 5rem;
    background-color: #f1eee6;
    border-radius: 0.6rem;
  }

  .doctor-card .left .img-cont img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .doctor-card {
    padding: 1rem;
    border: none;
    margin: 1.2rem 0;
  }
}

@media (min-width: 375px) {
  .doctor-card .alte-right button:nth-of-type(1) {
    padding: 0.7rem 1rem;
    font-size: 0.8rem;
  }

  .doctor-card .alte-right button.book {
    padding: 0.7rem 0.8rem;
    font-size: 0.8rem;
  }
}

@media (min-width: 412px) {
  .doctor-card .alte-right button:nth-of-type(1) {
    padding: 0.7rem 1.2rem;
    font-size: 0.9rem;
  }

  .doctor-card .alte-right button.book {
    padding: 0.7rem 0.8rem;
    font-size: 0.9rem;
  }
}

@media (min-width: 425px) {
  .doctor-card .alte-right button:nth-of-type(1) {
    padding: 0.7rem 1.5rem;
    font-size: 0.9rem;
  }

  .doctor-card .alte-right button.book {
    padding: 0.7rem 0.8rem;
    font-size: 0.9rem;
  }
}

@media (min-width: 540px) {
  .doctor-card .alte-right button:nth-of-type(1) {
    padding: 0.8rem 1.3rem;
    font-size: 1.2rem;
  }

  .doctor-card .alte-right button.book {
    padding: 0.8rem 1.3rem;
    font-size: 1.2rem;
  }

  .doctor-card .left .img-cont {
    width: 6rem;
    height: 6rem;
  }

  .doctor-card .left {
    font-size: 1.2rem;
  }

  .doctor-card {
    padding: 1.2rem;
    margin: 1.4rem 0;
  }

  .doctor-card .left .col-2 {
    width: 23%;
  }

  .doctor-card .left .col-6 {
    width: 45%;
  }

  .text-muted-dashed {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .doctor-card {
    padding-bottom: 0;
  }
  .doctor-card .right {
    margin: 0 auto;
  }

  .doctor-card button {
    padding: 0.5rem 0.9rem;
    font-size: 0.9rem;
    width: -webkit-fill-available;
    width: fill-available;
  }

  .doctor-card .left .img-cont {
    width: 7rem;
    height: 7rem;
    /* width: 12rem;
    height: 12rem; */
  }

  .doctor-card .left .col-2 {
    width: 18%;
    /* width: 33%; */
  }
  .doctor-card .left .card-text {
    font-size: 1rem;
  }
  .doctor-card .row.left-cont {
    width: 44rem;
  }

  .right .book-link {
    display: flex;
    margin-top: 0.5rem;
    justify-content: center;
    align-items: center;
  }

  /* .doctor-card .right div.my-2.row {
    padding: 1rem 0;
  } */
}

@media (min-width: 1280px) {
  .doctor-card .left .img-cont {
    width: 9rem;
    height: 9rem;
  }
  .doctor-card .row.left-cont {
    width: 50rem;
  }
  .doctor-card .left .col-2 {
    width: 17%;
  }
  .right .book-link {
    justify-content: end;
  }
  .doctor-card .left .card-text {
    transform: translateX(20px);
  }
}
