.testo {
  padding: 30px 0;
  padding-bottom: 50px;
}

.testo h1 {
  font-weight: 400;
}

.testo span {
  color: #a99156;
}

.testo .testo-card {
  border: none;
  border-radius: 10px;
}

.testo .slick-slider .slick-list {
  padding: 20px 0;
}

.testo .testo-avatar {
  width: 80px;
  border-radius: 50%;
  margin: 2px auto;
}

.testo .testo-avatar img {
  width: 100%;
}

.rc-rate-star-half .rc-rate-star-first,
.rc-rate-star-full .rc-rate-star-second {
  color: #a99156 !important;
}
