.specialists {
  /* margin-top: 50px; */
  padding: 30px 0;
}

.specialists h1 {
  font-weight: 400;
}

.specialists h1 span {
  color: #a99156;
  font-weight: bold;
}

.specialists .slick-slide {
  text-align: start;
}

.specialists .slick-slider .slick-list {
  padding: 20px 0;
}

.specialists .mySlider {
  margin: 10px -12px;
}

.specialists .spec-card {
  box-shadow: -1px 0 10px #00000025;
  border-radius: 8px;
}

.specialists .slick-next {
  width: 35px;
  height: 35px;
  right: -35px;
}

.specialists .slick-prev {
  width: 35px;
  height: 35px;
  left: -35px;
}

.specialists .slick-dots li button:before {
  font-size: 18px;
  color: #a99156;
}

.specialists .slick-dots li button::before {
  content: "";
  height: 12px;
  width: 18px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #a99156;
  border-radius: 40%;
}

.specialists .slick-dots {
  bottom: -1rem;
}

.specialists .slick-dots li.slick-active button:before {
  color: #a99156;
}

.specialists .slick-slide {
  padding: 0 12px;
}
.specialists .img-cont {
  overflow: hidden;
  background-color: #f1eee6;
}

.specialists .img-cont .top-img {
  margin: auto;
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.specialists .slick-slide img {
  display: inline-block;
}

.specialists a {
  color: #a99156;
  text-decoration: none;
}

@media (max-width: 768px) {
  .spec-card {
    font-size: 0.8rem;
  }
}
@media (min-width: 360px) {
  .specialists .img-cont {
    height: 16rem;
  }
  .specialists .img-cont .top-img.specialist {
    object-fit: cover;
    border-radius: 8px;
  }
  .spec-card-body {
    height: 13rem;
  }
  .card-top-body.specialist {
    height: 5rem;
  }
}
@media (min-width: 1024px) {
  .scan-card-body.specialist {
    height: 17rem;
  }
  .card-top-body.specialist {
    height: 13rem;
  }
}
@media (min-width: 1280px) and (max-width: 1400px) {
  .scan-card-body.specialist {
    height: 18rem;
  }
}
