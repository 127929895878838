.doc-info.lab {
    padding-bottom: 1rem;
    margin-bottom: 1rem
}

@media (min-width:768px) {
    .doc-info .doc-info-cont .img-cont.lab {
        width: 6rem;
        height: 6rem;

    }
}