.offers {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background-position: right;
  background: linear-gradient(to right, #dfc483, #e9d39e);
}

.offers h1 span {
  color: white;
}

@media (min-width: 360px) {
  .offers h1 {
    font-size: 0.8rem;
    margin: 0.5rem 0;
  }

  .offers-cont {
    padding: 30px 0;
  }

  .offers .offer-slide {
    min-height: 10rem;
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }

  .offers .offer-slide .offer-slide-col {
    padding: 0.5rem;
    padding-right: 0;
  }

  .offers button {
    color: #a99156;
    background-color: white;
    border: none;
    padding: 0.2rem 0.8rem;
    border-radius: 500px;
    cursor: default;
    font-weight: bold;
    font-size: 0.7rem;
  }

  .offers a button {
    padding: 0.4rem 0.8rem;
    border-radius: 0.4rem;
    background-color: #a99156;
    color: white;
    outline: none;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.7rem;
  }

  .offers .slick-slide img {
    display: inline-block;
  }
}

@media (min-width: 540px) {
  .offers h1 {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) {
  .offers h1 {
    font-size: 1rem;
  }

  .offers .offer-slide .offer-slide-col {
    padding: 1rem;
  }

  .offers .offer-slide .offer-slide-col.col-8 {
    width: 55%;
  }

  .offers button {
    padding: 0.4rem 2rem;
    font-size: 0.9rem;
  }

  .offers a button {
    font-size: 0.9rem;
  }
}

@media (min-width: 1024px) {
  .offers h1 {
    font-size: 1.5rem;
  }

  .offers button {
    font-size: 1rem;
  }

  .offers a button {
    font-size: 1rem;
  }
}

@media (min-width: 1280px) {
  .offers h1 {
    font-size: 2.5rem;
  }

  .offers button {
    font-size: 1.2rem;
  }

  .offers a button {
    font-size: 1.2rem;
    padding: 10px 20px;
  }
}

@media (min-width: 1440px) {
  .offers h1 {
    font-size: 2.8rem;
    margin: 1.3rem 0;
  }

  .offers button {
    font-size: 1.2rem;
  }

  .offers a button {
    font-size: 1.2rem;
    padding: 10px 20px;
  }
}
