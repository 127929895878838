.top-navbar {
  font-weight: 500;
  height: 85px;
  background-color: #fef8e9;
}
.container:lang(ar) {
  direction: rtl;
}
.top-navbar .booking.dropdown-menu {
  border: transparent;
}
.top-navbar .bookingAppointment.dropdown-menu {
  border: transparent;
  background-color: transparent;
}
.logo {
  cursor: pointer;
}
.logo img {
  width: 70px;
}

.sign-up {
  color: white;
}

.log-in {
  color: #a99156;
  background-color: transparent;
}

.top-navbar button {
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 10px;
}

.navbar-nav a {
  color: black;
  text-decoration: none;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-nav {
  align-items: center;
}

.navbar-nav .dropdown-menu {
  background-color: #fef8e9;
}

.dropdown-item {
  background-color: transparent;
}

/* @media screen and (min-width: 768px) {
  .canvas {
    display: none !important;
  }
} */

.offcanvas-top {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.cart .dropdown-toggle::after {
  display: none !important;
}
.blob {
  border-radius: 50%;
  margin: 10px;
  height: 30px;
  width: 30px;
  background: #111f30;
  box-shadow: 0 0 0 rgba(21, 26, 51, 0.4);
  transform: scale(1.1);
  animation: pulse 2s infinite;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

@-webkit-keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(19, 25, 58, 0.4);
    box-shadow: 0 0 0 0 rgba(26, 29, 59, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(19, 25, 58, 0.4);
    box-shadow: 0 0 0 0 rgba(26, 29, 59, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@media screen and (max-width: 768px) {
  /* .responsive-nav {
    display: none !important;
  } */

  /* .canvas {
    display: flex;
    align-items: center;
  }

  .navbar-nav a span {
    display: none;
  } */

  .navbar-nav {
    /* background: #fef8e9; */
    /* box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.226); */
    /* padding: 20px 0; */
  }

  .navbar-menu {
    width: 100vw;
    position: fixed;
    left: 0;
    right: 0;
    top: 85px;
    /* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15); */
    text-align: center;
  }

  .navbar-right {
    align-items: center;
  }

  .cart {
    padding: 10px;
    margin: 0;
  }
}
