.contactPage .contact-form button {
  border: none;
  font-weight: 500;
  padding: 10px 50px;
  border-radius: 8px;
  text-transform: uppercase;
  color: white;
  background-color: #a99156;
}
.contactPage {
  margin-top: 50px;
}
.contactPage .contact-form {
  background-color: white;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: -1px 0 10px #00000025;
}
.extra-items-box {
  margin: 1rem 0;
}
.extra-item {
  position: relative;
  margin: 0 0.5rem;
  background-color: #fef8e9;
  border: 1px solid #a99156;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}
.remove-item {
  cursor: pointer;
  position: absolute;
  background-color: #a99156;
  color: white;
  padding: 0.1rem 0.2rem;
  font-size: 0.7rem;
  top: -0.1rem;
  right: -0.15rem;
  z-index: 1;
}
.contactPage .contact-form .form-control {
  background-color: #fcfaf3;
  border: 1px solid #e2d6b6;
  border-radius: 8px;
}

.contactPage h3 {
  color: #355476;
  margin-top: 20px;
}

.contactPage a:nth-of-type(1) {
  font-weight: bold;
  color: black;
  text-decoration: none;
  font-size: 18px;
}

.contactPage a.direction {
  font-weight: bold;
  color: #a99156;
  display: block;
  font-size: 16px;
  text-decoration: underline;
}

.contactPage iframe {
  border-radius: 10px;
}
