.footer-top {
  background-color: #26486d;
  color: white;
}

.footer-bottom {
  background-color: #e6d5ad;
}

.links {
  display: flex;
  flex-direction: column;
}

.links a {
  text-decoration: none;
  color: black;
  margin: 5px 0;
}

.footer-bottom .feedback button {
  outline: none;
  border: none;
  color: #ac955c;
  padding: 8px 20px;
  border-radius: 10px;
}

.footer-top button {
  background-color: #a99156;
  color: white;
  font-weight: 500;
  outline: none;
  border: none;
  padding: 0.7rem 0.75rem;
  border-radius: 10px;
  text-transform: uppercase;
}

/* 
.footer-top h1 {
  width: 50%;
  text-align: center;
}

.footer-top span {
  color: #a99156;
  font-weight: bold;
}

.footer-top input {
  min-width: 400px;
}



*/

/* @media screen and (max-width: 768px) {
  .footer-top h1 {
    width: 100%;
    margin-bottom: 30px;
  }

  .footer-top {
    padding: 60px 70px;
  }

  .footer-top input {
    min-width: 180px;
  }

  .footer-bottom {
    padding: 30px 20px;
  }

  .footer-bottom .col {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 470px) {
  .footer-top {
    padding: 40px 5px;
  }
} */
@media (min-width:360px) {
  .footer-bottom .col-6 {
    margin: 1rem 0;
  }

  .footer-bottom .col-6 h3 {
    font-weight: bold;
  }

  .footer-bottom .form-control {
    background-color: #fef8e8;
    border-radius: 0.5rem;
    width: 100%;
  }

  .footer-top {
    padding: 3rem 0;
  }

  .footer-bottom {
    padding: 3rem 0;
  }

  .footer-top .form-control {
    padding: 0.7rem 0.75rem;
    border-radius: 0.55rem;
  }

  .footer-top h1 {
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 1rem
  }

  .footer-cont {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width:540px) {
  .footer-cont {
    justify-content: space-between;
  }

  .footer-top h1 {
    font-size: 2rem;
    margin-bottom: 2rem
  }

  .footer-top .footer-cont .control-cont {
    width: fill-available;
    width: -webkit-fill-available;
  }
}

@media (min-width:768px) {
  .footer-top h1 {
    width: 60%;
    font-size: 2.2rem;
    margin: 0 auto 3rem;
  }
}

@media (min-width:1024px) {
  .footer-cont {
    justify-content: center;
  }

  .footer-top .footer-cont .control-cont {
    width: 35%;
  }

  .footer-top {
    padding: 4rem 0;
  }

  .footer-bottom {
    padding: 4rem 0;
  }
}

@media (min-width:1280px) {
  .footer-top h1 {
    font-size: 3rem;
  }
}