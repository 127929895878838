.doc-services .service {
  display: flex;
  margin-bottom: 20px;
  border-radius: 8px;
  max-height: 10rem;
}

.doc-services .service .minusplus {
  background-color: #f4e4bd;
  border-radius: 5px;
  cursor: pointer;
}

.doc-services .service-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-services {
  position: relative;
}
.search-services .icon {
  position: absolute;
  left: 8px;
  top: 12px;
}

.doc-services .service .number {
  background-color: #26486d;
  color: #fff;
  font-size: 14px;
  padding: 0 6px;
  border-radius: 5px;
}

.doc-about .more,
.doc-direction a {
  text-decoration: underline;
  cursor: pointer;
  color: #a99156;
  font-weight: 400;
}

@media (min-width: 360px) {
  .doc-services .service-box {
    flex-direction: column;
  }

  .doc-services .service-box .form-control {
    border-radius: 0.4rem;
    border: #e0d3b2 2px solid;
    width: 17rem;
    text-indent: 10px;
  }
  .doc-services .modal-content {
    background: linear-gradient(to bottom, #fef8e9 2%, white 98%);
  }
  .doc-services,
  .doc-about,
  .doc-direction,
  .doc-faq,
  .doc-reviews {
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: -1px 0 10px #00000025;
    border-bottom: none;
    padding-bottom: 20px;
    margin-top: 20px;
  }
  .doc-faq {
    margin-bottom: 20px;
  }
  .doc-services.modal {
    background-color: transparent;
  }
  .doc-direction .map-cont {
    display: flex;
    flex-direction: column;
  }

  .doc-direction .map-cont .map {
    width: 100%;
    padding-right: 0;
    height: 200px;
  }

  .doc-direction .map-cont .text {
    width: 100%;
  }
  .doc-services .service {
    animation: fadeIn 0.5s ease-in-out;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .doc-services .service .service-img {
    border-radius: 8px;
    width: 40%;
  }

  .doc-services .service .service-img img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }

  .doc-services .service .cardText {
    padding: 0 0 0 0.5rem;
    font-size: 0.9rem;
  }

  .doc-services .service .mobile-buttons-cont {
    display: flex;
    align-items: flex-end;
  }

  .doc-services .service .mobile-buttons-cont .mobile-buttons {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
  }
}

@media (min-width: 540px) {
  .doc-services .service .cardText {
    font-size: 1.2rem;
  }
  .doc-services .service .mobile-buttons-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .doc-services .form-control {
    background-color: #fcfaf3;
    width: 25rem;
  }

  .doc-services .service h6 {
    font-size: 1.3rem;
  }

  .doc-faq,
  .doc-reviews {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .doc-services .service-box {
    flex-direction: row;
  }
}
@media (min-width: 992px) and (max-width: 1023px) {
  .doc-services .service .service-img {
    width: 25%;
  }
  .doc-services .col-lg-12 {
    width: 45%;
  }
  .doc-services .service {
    max-height: 10rem;
  }
  .doc-services .service .service-img img {
    object-fit: contain;
  }
}
@media (min-width: 1024px) {
  .doc-services .service-box .form-control {
    width: 20rem;
  }
  .doc-services .service .service-img {
    width: 25%;
  }
  .doc-services .service {
    max-height: 8rem;
  }
  .doc-direction .map-cont {
    flex-direction: row;
  }

  .doc-direction .map-cont .map {
    width: 70%;
    padding-right: 1rem;
  }

  .doc-direction .map-cont .text {
    width: 30%;
  }

  .doc-faq,
  .doc-reviews {
    padding-bottom: 20px;
    width: 52%;
    padding: 0 0 1rem 0;
    padding-bottom: 20px;
  }

  .doc-services,
  .doc-about,
  .doc-direction {
    padding-bottom: 20px;
    width: 52%;
    background-color: transparent;
    padding: 0 0 1rem 0;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 0.1rem solid #a99156;
    padding-bottom: 20px;
  }

  .doc-services .service .mobile-buttons-cont {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (min-width: 1200px) {
  .doc-services .service .service-img {
    width: 40%;
  }
  .doc-services .service {
    max-height: 10rem;
  }
}
@media (min-width: 1280px) {
  .doc-services .service-box .form-control {
    width: 25rem;
  }

  .doc-services .service h6 {
    font-size: 1rem;
  }

  .doc-services,
  .doc-about,
  .doc-direction,
  .doc-faq,
  .doc-reviews {
    width: 55%;
  }
}

@media (min-width: 1440px) {
  .doc-services,
  .doc-about,
  .doc-direction,
  .doc-faq,
  .doc-reviews {
    width: 60%;
  }
}
