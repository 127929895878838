.searchbar .tab-box {
  padding: 15px;
  border-radius: 5px;
  background-color: white;
  box-shadow: -1px 0 10px #00000025;
}

.searchbar .nav-tabs {
  margin-bottom: 0 !important;
}

.searchbar .nav-tabs .nav-link.active {
  color: white;
  background-color: #a99156;
  font-weight: 500;
  padding: 10px 20px;
}

.searchbar .nav-tabs .nav-link {
  color: white;
  background-color: #e6d5ad;
  font-weight: 500;
  padding: 10px 20px;
  border: none;
  outline: none;
}

.search-select-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.search-select-box .form-control {
  padding: 0.375rem 1.75rem !important;
  border: 1px solid #e2d6b6;
  border-radius: 0.5rem;
}

.search-select-box {
  position: relative;
}

.search-select-box .menu {
  padding: 0.7rem 1.5rem 1.2rem 2.3rem;
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  top: 0;
  z-index: 50;
}
.search-select-box .menu .menu__body {
  margin-bottom: 0.5rem;
}
.search-select-box .menu:after {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(237, 237, 237, 0.1);
  border-bottom-color: #ffffff;
  border-width: 10px;
  left: 20%;
  margin-left: -19px;
}

.search-select-box img {
  position: absolute;
  top: 10px;
  color: rgba(0, 0, 0, 0.527);
  z-index: 1;
}

.search-select-box .icon {
  left: 8px;
}

.search-select-box .arrow {
  right: 8px;
}
.search-select-box .mySlider span {
  width: 40%;
}
.search-select-box .mySlider .slide span {
  font-size: 15px;
  color: rgb(0, 0, 0);
  line-height: 1.1;
  margin-bottom: 0.5rem;

  margin-right: 0.2rem;
  padding: 5px;
  border-radius: 5px;
}

.search-select-box .mySlider .slide span.active {
  background-color: #dbc17f57;
  padding: 5px;
  border-radius: 5px;
}

.search-select-box input {
  background-color: #fcfaf3;
}

.search-select-box .selection-box {
  position: absolute;
  z-index: 1;
  font-size: 12px;
  top: 10px;
  left: 34px;
}

.search-select-box .selection-box span {
  margin: 5px;
  background-color: #dbc17f57;
  color: rgb(0, 0, 0);
  padding: 5px;
  border-radius: 5px;
}

@media (min-width: 360px) {
  .search-select-box .page-num {
    transform: translateX(15rem);
    position: absolute;
    bottom: 0.5rem;
  }

  .offcanvas {
    background: linear-gradient(to bottom, #fef8e983 2%, white 98%);
  }

  .search-select-box .mobile-searchbar-button {
    background-color: #fcfaf3;
    color: #212529;
    width: -webkit-fill-available;
    width: fill-available;
    text-align: start;
    padding: 0.375rem 1.75rem;
    border: 1px solid #e2d6b6;
    border-radius: 0.5rem;
  }

  .mobile-searchbar-item {
    padding: 0.5rem;
    border-bottom: 0.05rem solid #a9925660;
  }

  .mobile-searchbar-selection-box .form-control {
    padding: 0.375rem 1.75rem !important;
    border: 1px solid #e2d6b6;
    border-radius: 0.5rem;
  }

  .mobile-searchbar-item.active {
    background-color: #dbc17f57;
    padding: 5px;
    border-radius: 5px;
  }
  .all-selected {
    background-color: #dbc17f57;
    border-radius: 5px;
  }
  .mobile-searchbar-selection-box {
    position: relative;
  }

  .mobile-searchbar-selection-box .selection-box {
    position: absolute;
    top: 7px;
    font-size: 0.9rem;
    left: 10px;
  }

  .mobile-searchbar-selection-box .selection-box span {
    margin: 5px;
    background-color: #dbc17f57;
    color: rgb(0, 0, 0);
    padding: 5px;
    border-radius: 5px;
  }

  .search-select-box .slick-next {
    right: -1rem;
    top: 50%;
    padding: 1px;
    border: 1px solid #a99156;
  }

  .search-select-box .slick-list {
    padding-top: 10px;
    max-height: 300px;
    overflow-y: auto;
  }
  .search-select-box .specialities-services-container {
    width: 50%;
  }
  .search-select-box .specialities-services-container .specialities-services {
    display: flex;
    flex-direction: column;
    list-style-type: square;
  }
  .search-select-box
    .specialities-services-container
    .specialities-services
    li {
    padding: 2px;
    margin: 2px 0;
    cursor: pointer;
  }

  .search-select-box
    .specialities-services-container
    .specialities-services
    li.active {
    background-color: #dbc17f57;
    border-radius: 5px;
  }
  .search-select-box .slick-prev {
    left: -1.4rem;
    top: 50%;
    padding: 1px;
    border: 1px solid #a99156;
  }

  .searchbar .nav-tabs .nav-item:nth-child(1) .nav-link,
  .searchbar .nav-tabs .nav-item:nth-child(2) .nav-link,
  .searchbar .nav-tabs .nav-item:nth-child(3) .nav-link {
    width: 100%;
  }

  .searchbar .nav-tabs .nav-item {
    width: 50%;
  }

  .searchbar .nav-tabs .nav-item:nth-child(1) .nav-link {
    border-top-right-radius: 0;
    border-top-left-radius: 0.5rem;
  }

  .searchbar .nav-tabs .nav-item:nth-child(2) .nav-link {
    border-top-left-radius: 0;
    /* border-top-right-radius: 0; */
    border-top-right-radius: 0.5rem;
  }
  .searchbar .nav-tabs .nav-item:nth-child(3) .nav-link {
    border-top-left-radius: 0;
    border-top-right-radius: 0.5rem;
  }

  .searchbar button {
    outline: none;
    border: none;
    background-color: #a99156;
    color: white;
    padding: 0.3rem 0.6rem;
  }

  .searchbar .search {
    border-radius: 0.4rem;
    margin: 0.8rem 0;
    background-color: #26486d;
    color: #a99156;
    padding: 0.5rem 0.6rem;
  }

  .searchbar .search span {
    color: #d1c39d;
  }

  .search-select-container {
    flex-direction: column;
  }

  .search-select-container {
    align-items: inherit;
  }

  .search-select-box {
    margin: 0.4rem;
    position: relative;
  }
  .search-select-box small {
    position: absolute;
    bottom: -1.2rem;
    left: 0.2rem;
    color: red;
  }

  .searchbar .nav-tabs .nav-link.active {
    padding: 0.5rem;
  }

  .searchbar .nav-tabs .nav-link {
    padding: 0.5rem;
  }
}

@media (min-width: 540px) {
  .search-select-box .page-num {
    transform: translateX(24rem);
  }

  .search-select-box .slick-prev {
    left: -1.4rem;
  }
}

@media (min-width: 768px) {
  .search-select-box .menu {
    width: 650px;
    max-width: 1000px;
    border: none;
    box-shadow: 2px 0px 10px #65656561;
  }

  .over-lay-click {
    position: fixed;
    width: 200vw;
    height: 200vh;
    background-color: transparent;
    z-index: 49;
    top: -550px;
    left: -550px;
  }

  .search-select-box .page-num {
    transform: translateX(32rem);
  }
}

@media (min-width: 1024px) {
  .search-select-box .menu {
    width: 400px;
    transform: translate3d(0.5px, 46px, 0px) !important;
  }

  .search-select-container {
    flex-direction: row;
  }

  .search-select-box .form-control {
    width: 195px;
  }

  .searchbar .search {
    outline: none;
    border: none;
    margin: 0.3rem 0;
    background-color: #a99156;
    color: white;
    padding: 0.3rem 0.6rem;
  }

  .searchbar .nav-tabs .nav-item:nth-child(1) .nav-link,
  .searchbar .nav-tabs .nav-item:nth-child(2) .nav-link {
    width: inherit;
  }

  .searchbar .nav-tabs .nav-item {
    width: 10rem;
  }

  .search-select-box .page-num {
    transform: translateX(20rem);
  }
}

@media (min-width: 1200px) {
  .search-select-box .form-control {
    width: 230px;
  }
}

@media (min-width: 1280px) {
  .search-select-box .menu {
    width: 480px;
  }

  .search-select-box .page-num {
    transform: translateX(25rem);
  }
}

@media (min-width: 1400px) {
  .search-select-box .form-control {
    width: 275px;
  }
}

@media (min-width: 1440px) {
  .search-select-box .menu {
    width: 560px;
  }

  .search-select-box .page-num {
    transform: translateX(28rem);
  }
}
