.AboutPage {
  margin-top: 50px;
}

.about-section .about-section {
  margin-bottom: 2.3rem;
}

.about-section img {
  border-radius: 8px;
  width: 100%;
}

@media (min-width: 360px) {
  .about-section.rev {
    flex-direction: column-reverse;
  }
}

@media (min-width: 1024px) {
  .about-section.rev {
    flex-direction: row-reverse;
  }
}
