.doc-reviews a {
  color: #a99156;
  font-weight: 400;
}

.doc-reviews .reviews-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;
}

.doc-reviews .review-card {
  background-color: white;
  box-shadow: -1px 0 10px #00000025;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
}

.doc-reviews .review-avatar {
  width: 50px;
  border-radius: 50%;
  margin: 2px auto;
}

.doc-reviews .review-avatar img {
  width: 40px;
}

.doc-reviews .rc-rate {
  font-size: 13px !important;
}

@media screen and (max-width: 1024px) {
  .doc-reviews .review-card h6 {
    margin-bottom: 0;
  }

  .rc-rate {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 426px) {
  .doc-reviews .reviews-box {
    flex-direction: column;
  }

  .doc-reviews .review-card {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 15px;
    width: 100%;
    margin-bottom: 10px;
    border-bottom: #a99156 2px solid;
  }

  .doc-reviews .review-card:last-child {
    border-bottom: none;
  }
}
