.side-filter .accordion {
  border-bottom: #a99156 2px solid;
  padding-bottom: 0.8rem;
}

.side-filter .accordion.filter-top {
  border-bottom: none;
}

.mobile-filter-body {
  border-top: #a99156 2px solid;
  border-left: #a99156 2px solid;
  border-right: #a99156 2px solid;
  border-radius: 8px;
}

.offcanvas-header {
  background-color: #a99156;
  color: white;
}

.side-filter .accordion .accordion-body {
  background-color: #fff;
}

.side-filter .accordion .accordion-body.main-head-cont {
  border-left: #a99156 2px solid;
  border-right: #a99156 2px solid;
  background-color: #fff;
}

.side-filter .accordion-item {
  border: none;
  background-color: #fef8e8;
}

.side-filter .form-check-input:checked {
  background-color: #a99156;
  border-color: #a99156;
}

.side-filter .rc-slider-handle,
.mobile-filter-body .rc-slider-handle {
  border: #a992562d 2px solid;
}

.side-filter .rc-slider-handle-click-focused:focus,
.mobile-filter-body .rc-slider-handle-click-focused:focus {
  border-color: #a99156;
}

.side-filter
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging,
.mobile-filter-body
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #a9925675;
  box-shadow: 0 0 0 5px #a992566b;
}

.side-filter .rc-slider-track,
.mobile-filter-body .rc-slider-track {
  background-color: #a992560c;
}
.side-filter .rc-slider {
  width: 80%;
  margin: auto;
}
.side-filter .accordion-button,
.side-filter .accordion-button:not(.collapsed),
.mobile-filter-body .accordion-button,
.mobile-filter-body .accordion-button:not(.collapsed) {
  color: #a99156;
  background-color: white;
  box-shadow: none;
}

.doc-list-cont {
  display: flex;
}

@media (min-width: 360px) {
  .side-filter {
    flex: 1;
    padding: 12px 0;
  }

  .side-filter .accordion .accordion-body {
    padding: 0 0 0.8rem;
  }

  .side-filter .accordion-button:not(.collapsed)::after,
  .side-filter .accordion-button::after,
  .mobile-filter-body .accordion-button:not(.collapsed)::after,
  .mobile-filter-body .accordion-button::after {
    background-image: url(/public/assets/arrowup.png);
  }

  .doc-list-cont {
    flex-direction: column;
  }

  .side-filter .accordion .accordion-body .form-check {
    padding-left: 2em;
  }

  .side-filter-flex {
    display: flex;
    justify-content: space-between;
    z-index: 80;
    left: 50%;
    transform: translate(-50%, 0px);
  }

  .side-filter .main-head .accordion-header {
    background-color: #fef8e8;
  }

  .side-filter button.mobileScreen {
    background-color: #fef8e8;
    padding: 0.6rem 1.7rem;
    color: #000;
    border-radius: 500px !important;
    border: 0.1rem solid #e0d2ae;
    width: 175px;
  }
}
@media (min-width: 540px) {
  .side-filter button.mobileScreen {
    width: 230px;
  }
}
@media (min-width: 768px) {
  .side-filter button.mobileScreen {
    width: 300px;
  }
}
@media (min-width: 1024px) {
  .side-filter .filter-top .main-head .accordion-header button {
    background-color: #a99156;
    color: #fff;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 0;
    border: none;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  .side-filter-flex {
    transform: translate(0, 0px);
  }
  .doc-list-cont {
    flex-direction: row;
  }

  .side-filter .accordion .accordion-body {
    padding: 0;
  }

  .side-filter-flex {
    display: block;
    padding-right: 1.5rem;
  }

  .side-filter .accordion .accordion-body.main-head-cont {
    left: 0;
  }
}
