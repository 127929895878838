.specialists .scan-card {
  box-shadow: -1px 0 10px #00000025;
  border-radius: 8px;
}
.oneCard {
  width: 33%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .oneCard {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .oneCard {
    width: 100%;
  }
}
.scan-card-body {
  height: 15rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.specialists .scan-card img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.card-top-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 10rem;
}
.specialists .scan-more {
  border: none;
  font-weight: 400;
  padding: 10px 40px;
  border-radius: 10px;
  color: white;
  background-color: #a99156;
}

.specialists .scan-more a {
  color: white;
}

.mySlider {
  display: flex;
  justify-content: center;
}
