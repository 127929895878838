.booking-card {
  box-shadow: -1px 0 10px #00000025;
  background-color: white;
  border-radius: 10px;
  position: relative;
  z-index: 200;
  max-height: 90%;
  overflow-y: scroll;

  /* padding-right: -30px; */
  /* scrollbar-width: 0px !important; */
  /* scroll-behavior: unset; */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.booking-card:lang(ar) {
  left: 5%;
}
.booking-card::-webkit-scrollbar {
  display: none;
}

.booking-card .booking-header {
  text-align: center;
  background-color: #a99156;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  color: white;
  font-weight: bold;
}

.booking-card .booking-item {
  border-bottom: 0.5px solid #f1eee6;
}

.modal-content {
  border-radius: 0.8rem !important;
}

.booking-card .booking-item .col-8.book {
  width: 69%;
  padding: 0;
}

.booking-card .swiper-slide,
.booking-modal .swiper-slide {
  text-align: center;
  font-weight: bold;
}

.booking-card .booking-item.no-bottom-border {
  border-bottom: none;
}

.booking-card .Completed {
  color: green;
}

.booking-card .Canceled {
  color: red;
}

.booking-card .booking-item.no-bottom-border.appointment {
  padding: 15px 25px;
}

.booking-card .slick-list {
  overflow: visible;
}

.booking-card .row {
  overflow: hidden;
}

:is(.booking-card, .booking-modal) button {
  background-color: #fef8e8;
  border: 1px solid #a99156;
}

.booking-card .swiper-slide {
  cursor: pointer;
}

:is(.booking-card, .booking-modal) button {
  border: 1px solid #a99156 !important;
}

.facebook-google {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.my-facebook-button .fa-facebook {
  margin-right: 5px;
}

.booking-card .or-box {
  position: relative;
}

.booking-card .or-box hr {
  height: 0.1rem;
  background-color: black;
  opacity: 1;
}

.booking-card .or-box span {
  position: absolute;
  background-color: white;
  top: 0;
  padding: 5px 12px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.booking-card .mail-phone {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.booking-card .form-control {
  background-color: #fcfaf3;
  border: 2px solid #e4d8bb;
  border-radius: 10px;
}

.booking-card .w-40 {
  width: 48%;
}

.booking-card .w-30 {
  width: 38%;
  margin: 0.5rem auto;
  color: #a99156;
  background-color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  padding: 10px 20px;
  border: #a99156 2px solid;
}

.black-overlay {
  position: fixed;
  top: -100px;
  width: 100vw;
  height: 120vh;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.664);
}

.booking-card button.lab {
  background-color: #fef8e8;
  border: 1px solid #a99156;
  padding: 4px 10px;
  font-size: 15px;
  border-radius: 10px;
  width: 200px;
  cursor: default;
}

.booking-card .booking-item .swiper-slide {
  text-align: center;
}

@media (min-width: 360px) {
  .booking-card .mobileCard-hide {
    display: none;
  }

  .modalHead {
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #a99156;
  }

  .bookingFormPadding {
    padding: 0 1rem;
  }

  .booking-card .my-facebook-button {
    background-color: #3a559f;
    border: none;
    color: white;
    padding: 0.5rem 4.5rem;
    width: max-content;
    border-radius: 8px;
  }

  .booking-card button.my-google-button {
    background-color: #fff;
    border: 1px solid #6a8097;
    color: rgb(0, 0, 0);
    font-weight: bold;
    width: max-content;
    padding: 0.5rem 5rem;
    border-radius: 8px;
  }

  .booking-card .w-60 {
    width: 60%;
    background-color: #a99156;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 20px;
    border: none;
    margin: 0.5rem auto;
  }

  .booking-card .w-60 a {
    color: #fff;
    text-decoration: none;
  }

  .booking-card {
    margin: 1rem 0;
  }

  .booking-card .mobileCard-show {
    display: flex;
  }

  .booking-card .mobileCard-show span {
    color: #a99156;
    text-decoration: underline;
    font-weight: 500;
  }

  :is(.booking-card, .booking-modal) button {
    padding: 0.3rem 0.5rem;
    font-size: 0.7rem;
    border-radius: 0.4rem;
    width: fill-available;
    width: -webkit-fill-available;
  }

  .booking-modal button {
    font-size: 0.8rem;
  }

  .booking-modal .booking-card {
    margin: 0;
  }

  .booking-card .slick-prev,
  .booking-modal .slick-prev {
    left: -3px;
  }

  .booking-card .slick-next,
  .booking-modal .slick-next {
    right: -3px;
  }

  .booking-card .booking-item {
    padding: 0.5rem;
  }

  .locationSelectionBox {
    width: 12rem;
  }

  /* .booking-card .booking-item.no-bottom-border.slidz {
    padding: 1rem;
  } */
}

@media (min-width: 375px) {
  .booking-card button {
    font-size: 0.8rem;
  }
}

@media (min-width: 540px) {
  :is(.booking-card, .booking-modal) button {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .booking-card .mobileCard-hide {
    display: flex;
  }

  .booking-card .slick-prev,
  .booking-modal .slick-prev {
    left: -12px;
  }

  .booking-card .slick-next,
  .booking-modal .slick-next {
    right: -12px;
  }

  .booking-card .mobileCard-show {
    display: none;
  }

  :is(.booking-card, .booking-modal) button {
    padding: 0.5rem 0.5rem;
    font-size: 1.2rem;
  }
}

@media (min-width: 1024px) {
  .appointmentsSlidingBox {
    max-height: 15rem;
    overflow-y: scroll;
  }

  .booking-card {
    box-shadow: -1px 0 10px #00000025;
    background-color: white;
    border-radius: 10px;
    position: fixed;
    left: 52.5%;
    width: 28rem;
    margin: 0;
  }

  .locationSelectionBox {
    width: 15rem;
  }

  .booking-card .w-60 {
    width: 60%;
    background-color: #a99156;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 20px;
    margin: 0.5rem 0;
    border: none;
  }

  .booking-card button {
    font-size: 1rem;
  }

  .booking-card .w-30 {
    margin: 0.5rem 0;
  }

  .booking-card .mail-phone {
    flex-direction: row;
  }
}

@media (min-width: 1280px) {
  .booking-card {
    left: 56.5%;
    width: 30rem;
  }

  .booking-card .booking-item {
    padding: 0.5rem;
  }
}

@media (min-width: 1440px) {
  .booking-card {
    left: 60%;
    width: 32rem;
  }

  .booking-card .my-facebook-button {
    padding: 0.5rem 2.2rem;
  }

  .booking-card button.my-google-button {
    padding: 0.5rem 2.2rem;
  }

  .booking-card .booking-item {
    padding: 0.7rem;
  }
}

@media (min-width: 1920px) {
  .booking-card {
    left: 57.5%;
    width: 32rem;
  }

  .booking-card .booking-item {
    padding: 0.7rem;
  }
}
