.doc-profile-info-cont {
  display: flex;
}

.doc-about .borderTop {
  margin-top: 10px;
  border-top: 2.2px solid #a99156;
}

.doc-info .doc-info-cont {
  display: flex;
}

@media (min-width: 360px) {
  .doc-profile-info-cont {
    flex-direction: column;
  }

  .doc-info .doc-info-cont .img-cont {
    width: 5rem;
    overflow: hidden;
    height: 5rem;
    background-color: #f1eee6;
    border-radius: 0.6rem;
  }

  .doc-info .doc-info-cont .img-cont img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: 540px) {
  .doc-info .doc-info-cont .img-cont {
    width: 8rem;
    height: 8rem;
  }

  .doc-info .recommend {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .doc-info {
    margin-bottom: 1.8rem;
  }
}

@media (min-width: 768px) {
  .doc-info .doc-info-cont .img-cont {
    width: 10rem;
    height: 10rem;
  }

  .doc-info .recommend {
    justify-content: space-between;
    flex-direction: row;
    padding: 0.5rem 0;
    border-top: 0.1rem solid #a99156;
    border-bottom: 0.1rem solid #a99156;
  }
}

@media (min-width: 1024px) {
  .doc-info .doc-info-cont .img-cont {
    width: 8rem;
    height: 8rem;
  }

  .doc-info .recommend {
    width: 52%;
    padding: 0;
    flex-direction: row;
  }
}

@media (min-width: 1280px) {
  .doc-info .doc-info-cont .img-cont {
    width: 10rem;
    height: 10rem;
  }

  .doc-info .recommend {
    width: 55%;
    padding: 0.5rem 0;
    flex-direction: row;
  }
}

@media (min-width: 1440px) {
  .doc-info .doc-info-cont .img-cont {
    width: 12rem;
    height: 12rem;
  }

  .card-text .card-title {
    font-size: 1.5rem;
  }

  .doc-info {
    font-size: 1.3rem;
  }

  .doc-info .recommend {
    width: 60%;
  }
}

@media (min-width: 1920px) {
  .doc-info .doc-info-cont .img-cont {
    width: 12rem;
    height: 12rem;
  }

  .card-text .card-title {
    font-size: 1.5rem;
  }

  .doc-info {
    font-size: 1.3rem;
  }

  .doc-info .recommend {
    width: 60%;
  }
}
